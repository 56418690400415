const navMenuItems = [
    {
    	title: 'Accueil',
        route: 'home',
        icon: 'HomeIcon',
		resource: 'home',
		action: 'read',
    },

	{
		title: 'Gestion',
		children: [
			{
				title: 'DECs',
				route: 'decs/list',
				resource: 'gestion',
				action: 'read',
			},
			{
				title: 'Affaires',
				route: 'affaires/list',
				resource: 'gestion',
				action: 'read',
			},
			{
				title: 'Tableaux de Gestion',
				route: 'tableau_gestions/list',
				resource: 'gestion_direction',
				action: 'read'
			},
			{
				title: 'Tableaux de Bord',
				route: 'tableau_bord/list',
				resource: 'gestion_direction',
				action: 'read',
			}
		]
	},
	
	{
		title: 'Paramètres',
		icon: 'SettingsIcon',
		children: [
			{
				title: 'Clients',
				route: 'clients/list',
				resource: "params",
				action: "read",
			},
			{
				title: 'Agences',
				route: 'agences/list',
				resource: "params",
				action: "read",
			},
			{
				title: 'Utilisateurs',
				route: 'utilisateurs/list',
				resource: "params",
				action: "read",
			},
			{
				title: 'Types Affaire',
				route: 'affaire_types/list',
				resource: "params",
				action: "read",
			},
		]
	}
]

export default navMenuItems

// TODO => Paramètres ne doit être visible qu'en fonction des droits.