<template>
	<div class="navbar-container d-flex content align-items-center">
		<!-- Nav Menu Toggler (Bouton hamburger qui ouvre menu latéral, visible écran plus petit)-->
		<ul class="nav navbar-nav d-xl-none">
			<li class="nav-item">
				<b-link
				class="nav-link"
				@click="toggleVerticalMenuActive"
				>
				<feather-icon
					icon="MenuIcon"
					size="21"
				/>
				</b-link>
			</li>
		</ul>

		<!-- Left Col -->
		<div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
			<dark-Toggler class="d-none d-lg-block" />
		</div>

		<b-col lg="10">
			<breadcrumb />
		</b-col>

		<b-navbar-nav class="nav align-items-center ml-auto">
			<b-nav-item-dropdown
				right
				toggle-class="d-flex align-items-center dropdown-user-link"
				class="dropdown-user"
			>
				<template #button-content>
					<div class="d-sm-flex d-none user-nav">
						<p class="user-name font-weight-bolder mb-0">
							{{username}}
						</p>
						<span class="user-status">{{trigramme}}</span>
					</div>
					<img :src="appLogoImage" height="50" class="logo_nav_bar user-nav"/>
				</template>

				<b-dropdown-item link-class="d-flex align-items-center" @click="disconnect">
					<feather-icon
						size="16"
						icon="LogOutIcon"
						class="mr-50"
					/>
					<!-- TODO -->
					<span>Se déconnecter</span>
				</b-dropdown-item>
			</b-nav-item-dropdown>
		</b-navbar-nav>
	</div>
</template>

<script>
import {

  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'

import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Breadcrumb from '@/views/GDSoftBreadcrumb.vue'
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex';
import {$themeConfig} from '@themeConfig'

export default {
	components: {
		BLink,
		BNavbarNav,
		BNavItemDropdown,
		BDropdownItem,
		BDropdownDivider,
		Breadcrumb,

		// Navbar Components
		DarkToggler,
	},
	setup() {
		const { appName, appLogoImage } = $themeConfig.app;
		return {
			appLogoImage
		}
	},
	props: {
		toggleVerticalMenuActive: {
		type: Function,
			default: () => {},
		},
	},
	computed: {
		...mapState('data',['user']),
		username() {
			if (this.user != null)
				return this.user.firstname + ' ' + this.user.lastname;
			else
				return '';
		},
		trigramme() {
			if (this.user != null)
				return this.user.username;
			else
				return '';
		}
	},
	methods: {
		...mapActions('data',['logout']),
		async disconnect() {
			await this.logout();
			this.$router.push({name: 'login'});
		}
	}
}
</script>

<style scoped>
	.logo_nav_bar {
		box-shadow: none !important;
	}
</style>
